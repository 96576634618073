/** @format */

import React, { useEffect, useState } from "react";
import Navbar from "../Components/NavBar";
import backgroundVideo from "../Assets/BGVideo.mp4";
import bottomImg from "../Assets/bottomImg.png";
import VALUATIONSIMG from "../Assets/VALUATIONS.webp";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import img7 from "../Assets/img7.webp";

import ContactBtn from "../Components/ContactBtn";

import Footer from "../Components/Footer";
import {
  useGetServiceImage1Mutation,
  useGetServiceImage2Mutation,
  useGetServiceImage3Mutation,
  useServiceImage2Mutation,
  useServiceImage3Mutation,
} from "../Store/Api/Main";
import { Try } from "@mui/icons-material";

const textData = [
  "Prepare the economic valuation of the company",
  "Assist in defining the transaction terms and the values involved ",
  "Develop the company information memorandum",
  "Identify investors and/or target companies",
  "Negotiate with investors and/or target companies.",
  "Assist in drafting contractual clauses",
  "Coordinate the due diligence process",
  "Oversee the closing of the transaction.",
  "Assist in the preparation of contracts and agreements related to the transaction",
];
const imageUrls = [
  "https://img.freepik.com/free-photo/male-executive-explaining-graph_1098-2160.jpg?t=st=1731593620~exp=1731597220~hmac=a24ebf9f17611094d1bea20937a252e8d28e21d8230f1ae56fe72ba629091def&w=1380",
  "https://img.freepik.com/free-photo/person-looking-finance-graphs_52683-116610.jpg?t=st=1731594175~exp=1731597775~hmac=c00de07796c7c4b615a78d5589b114e2715f62ac13cde21f2ec69bc8e827c393&w=1380",
  "https://img.freepik.com/free-photo/business-team-situation-present-share-idea_1421-633.jpg?t=st=1731594232~exp=1731597832~hmac=2e6d44d1e79c78d24831dc38ba10f3f3b05a9dd7537770d147220bfc627ad155&w=1380",
  "https://img.freepik.com/free-photo/general-director-discussing-with-investor-increase-funding-project_482257-91213.jpg?t=st=1731594275~exp=1731597875~hmac=ef497fa20c72db14d348b7ccc8d03b24f565ef9933d7e11fab1d60b30b9dfc8e&w=1380",
  "https://img.freepik.com/free-photo/business-people-talking-table_23-2147626555.jpg?t=st=1731594534~exp=1731598134~hmac=1b63fcb6a7ffea99bc2e621c42633e784013a2cfae1ed211e8ebb9d016496f30&w=1380",
  "https://img.freepik.com/free-photo/business-people-discussing-contract_53876-119703.jpg?t=st=1731594610~exp=1731598210~hmac=f3e5845b0222a1ef9e948ec009ab9f7cb4db5e6b48ac9367378305c6c4e54c73&w=1380",
  "https://img.freepik.com/free-photo/picture-professional-business-presentation_329181-17088.jpg?t=st=1731594667~exp=1731598267~hmac=772c259c2240719b0753a2c7eee4964c3f50063be64658e34fe1ef74d8f000e4&w=1380",
  "https://img.freepik.com/free-photo/portrait-happy-male-female-partners-shaking-hands_1262-17976.jpg?t=st=1731594719~exp=1731598319~hmac=97b7a0b0a287350732d9115c15c1f16c7175dbc32c8dc9d172f191787d948281&w=1380",
  "https://img.freepik.com/free-photo/man-woman_144627-21046.jpg?t=st=1731594764~exp=1731598364~hmac=5a040cfc713d602c39c7eabb700ae2eec47e951900b4bcd67dddee2a61875918&w=740",
];
const MetrithaServices = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [ServiceImage1] = useGetServiceImage1Mutation();
  const [ServiceImage2] = useGetServiceImage2Mutation();
  const [ServiceImage3] = useGetServiceImage3Mutation();
  const [combinedData, setCombinedData] = useState({
    images: [],
    sliderImages: null,
  });
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? textData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === textData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const getImageFunctions = [ServiceImage2, ServiceImage3];

  const fetchAllImages = async () => {
    try {
      const imageResults = await Promise.all(
        getImageFunctions.map((fetchImage) => fetchImage())
      );

      const imageLocations = imageResults.map(
        (result) => result?.data?.location
      );

      const sliderResult = await ServiceImage1();

      const combinedData = {
        images: imageLocations,
        sliderImages: sliderResult?.data?.images,
      };

      setCombinedData(combinedData);

      console.log("Combined data saved to state:", combinedData);
      // console.log("SliderImages:", combinedData.sliderImages);
    } catch (error) {
      console.error("Error fetching all images:", error);
    }
  };

  useEffect(() => {
    fetchAllImages();
  }, []);

  return (
    <>
      <Box sx={{ position: "relative", height: "100vh", width: "100%" }}>
        <video
          autoPlay
          loop
          muted
          style={{
            position: "relative",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            mt: 2,
          }}
        >
          <Navbar mainHeading="Admin Dashboard" />

          <Grid container spacing={2} height={"50%"}>
            <Grid item xs={0} sm={3} md={3} lg={3}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ textAlign: "center" }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Poppins",
                  color: "#fff",
                  fontWeight: "thin",
                  fontSize: {
                    xs: "1.5rem",
                    sm: "2.5rem",
                    md: "3rem",
                    lg: "4rem",
                  },
                  letterSpacing: "1px",
                  lineHeight: "1.5",
                }}
              >
                Services
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <NewButton
              title='Get Started'
              bgColor='#331D48'
              onClick={undefined}
            />
          </Grid> */}
        </Box>
      </Box>
      <Grid container backgroundColor={"#331D48"} spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          sx={{
            mt: 4,
            mb: 2,
          }}
        >
          <Typography
            sx={{
              color: "white", // Set font color to white
              fontFamily: "Poppins",
              letterSpacing: "2px",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "2.2rem",
              },
              fontWeight: "300",
              padding: "10px 40px",
            }}
          >
            MERGERS & ACQUISITIONS
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        textAlign={"center"}
        flexDirection={"row"}
        sx={{
          pt: 4,
          pb: 8,
          backgroundColor: "#331D48",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" },
              fontWeight: "300",
              fontFamily: "Poppins",
              color: "#f0f0f0",
              textAlign: "left",
              py: { xs: 0, sm: 0, md: 10 },
              px: { xs: 2, sm: 4, md: 10 },
            }}
          >
            Companies seek growth, by increasing their market presence, locally
            and regionally, by achieving greater efficiencies and improving
            results.
            <br />
            <br />
            Shareholders’ aim to enhance the value and liquidity of their stake
            holdings. These objectives drive sector consolidations, strategic
            actions and succession decisions that energize the M&A market.
            <br />
            <br />
            Metritha has experience in both sell-side and buy-side transactions,
            providing the following services.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              position: "relative",
              width: { xs: "250px", sm: "350px", md: "400px", lg: "650px" },
              height: { xs: "200px", sm: "250px", md: "300px", lg: "450px" },
              backgroundImage:
                combinedData?.sliderImages?.length > 0
                  ? `url(${combinedData?.sliderImages[currentIndex]?.location})`
                  : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "10px",
              padding: "5px",
              border: "2px solid #666",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Overlay */}
            <Box
              sx={{
                position: "absolute", // Absolutely position to cover the image
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Grey overlay with some transparency
                borderRadius: "10px", // To match the rounded corners of the box
                zIndex: 0, // Ensures the overlay is behind the text
              }}
            />

            {/* Text */}
            <Typography
              variant="h6"
              sx={{
                color: "white", // White text to contrast with the grey overlay
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "1.4rem", // Increased font size for better readability
                fontWeight: "300", // Slightly bold for better visibility
                zIndex: 1, // Ensure text is above the overlay
              }}
            >
              {textData[currentIndex]}
            </Typography>

            {/* Left Arrow Button */}
            {currentIndex > 0 && (
              <IconButton
                onClick={handlePrev}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "-60px", // Adjusted to stay outside the box
                  transform: "translateY(-50%)",
                  color: "black",
                  zIndex: 1, // Ensure the button is above the overlay
                }}
              >
                <ArrowCircleLeftIcon sx={{ fontSize: 40, color: "#f0f0f0" }} />
              </IconButton>
            )}

            {/* Right Arrow Button */}
            {currentIndex < textData.length - 1 && (
              <IconButton
                onClick={handleNext}
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "-60px", // Adjusted to stay outside the box
                  transform: "translateY(-50%)",
                  color: "black",
                  zIndex: 1, // Ensure the button is above the overlay
                }}
              >
                <ArrowCircleRightIcon sx={{ fontSize: 40, color: "#f0f0f0" }} />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        backgroundColor={"#331D48"}
        sx={{
          Pt: 4,
          Pb: { xs: 1, sm: 2, md: 4, lg: 4 },
        }}
      >
        <Typography
          sx={{
            color: "white", // Set font color to white
            fontFamily: "Poppins",
            letterSpacing: "2px",
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              md: "1.5rem",
              lg: "2.2rem",
            },
            fontWeight: "300",
            padding: "15px 40px",
            mb: 2,
          }}
        >
          EQUITY PARTICIPATION
        </Typography>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#331D48",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          py: { xs: 1, sm: 2, md: 4, lg: 4 },
          px: { xs: 0, sm: 0, md: 6, lg: 6 },
        }}
      >
        <Grid xs={12} sm={6} md={6} lg={6}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "0.8rem", sm: "0.8rem", md: "1rem" },
              fontWeight: "300",
              letterSpacing: "2px",
              color: "#f0f0f0",
              textAlign: "left",
              padding: "10px 40px",
            }}
          >
            Companies in a growth face require long-term resources that can be
            provided by new investors. The following business activities are
            typically financed by equity investors:
          </Typography>
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={6}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "0.8rem", sm: "0.8rem", md: "1rem" },
              fontWeight: "300",
              letterSpacing: "2px",
              color: "#f0f0f0",
              textAlign: "left",
              padding: "10px 45px",
            }}
          >
            Metritha has extensive experience in raising capital from
            institutional investors, such as private equity funds and family
            offices, and provides the following services:
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#331D48",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Grid xs={12} sm={6} md={6} lg={6}>
          <List sx={{ px: { xs: 0, sm: 0, md: 8, lg: 10 } }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Acquisitions of companies.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Strengthening of capital structure.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Buyout of partner stakes.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Expansion of product lines and market regions.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 14, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Succession processes.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={6}>
          <List sx={{ padding: 5 }}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Analyze the company's situation and future prospects.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Prepare the economic valuation of the business.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Develop an information memorandum for investors.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Identify suitable investors to provide capital to the
                    company.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Negotiate with these investors regarding their stake in the
                    company.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 12, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    {/* Assist the company in due diligence and participation
                    agreements. */}
                    Expansion of product lines and regional markets. Assist the
                    company over due diligence and establish shareholder
                    aggreements.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: 12, sm: 12, md: 14, lg: 18 },
                      letterSpacing: "2px",
                      fontWeight: "300",
                      color: "#f0f0f0",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    Finalize the investment transaction with investors and
                    advise on contractual conditions.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          backgroundColor: "#331D48",
          py: 2,
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            pt: { xs: 1, sm: 2, md: 2, lg: 4 },
            pb: { xs: 1, sm: 2, md: 2, lg: 4 },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontFamily: "Poppins",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "2.2rem",
              },
              fontWeight: "300",
              letterSpacing: "2px",
            }}
          >
            LONG TERM FINANCING
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display={"flex"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              fontWeight: "300",
              letterSpacing: "2px",
              color: "#f0f0f0",
              paddingX: { xs: 1, sm: 2, md: 4, lg: 36 },
            }}
          >
            During expansion periods, companies need to invest in civil works,
            machinery, equipment, facilities, and technology, in addition to
            requiring working capital to support these investments.
            <br />
            <br />
            These resources can be obtained through long-term financing lines
            from international and national development institutions (IFC, BID
            Invest, CAF, DEG, Proparco).
            <br />
            <br />
            Metritha fully structures these long-term financing operations with
            these institutions. The process involves:
          </Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            gap: { xs: 1, sm: 2, md: 4, lg: 6 },
            mt: { xs: 1, sm: 2, md: 2, lg: 4 },
            paddingX: { xs: 1, sm: 2, md: 4, lg: 8 },
          }}
        >
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                color: "#f0f0f0",
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: { xs: 12, sm: 12, md: 8, lg: 20 },
                lineHeight: { xs: 1, sm: 2, md: 3, lg: 20 },
                letterSpacing: "2px",
              }}
            >
              <li style={{ marginBottom: "15px" }}>
                Analyzing the company's needs
              </li>
              <li style={{ marginBottom: "15px" }}>
                Defining the institution, financing line, amount, and
                appropriate guarantees
              </li>
              <li style={{ marginBottom: "15px" }}>
                Preparing the feasibility project tailored to each institution
              </li>
              <li style={{ marginBottom: "15px" }}>
                Overseeing the entire analysis process
              </li>
              <li style={{ marginBottom: "15px" }}>
                Negotiating with the institution on conditions, costs, terms,
                and guarantees
              </li>
              <li style={{ marginBottom: "15px" }}>
                Assisting with the contracting
              </li>
              <li style={{ marginBottom: "15px" }}>
                Monitoring the release of funds to the company
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} sx={{ width: "100%" }}>
            <img
              src={combinedData?.images[0] ? combinedData?.images[0] : null}
              alt="img7"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: "10px",
                aspectRatio: "16/9",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* -------------------------------------------- */}
      {/* <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#331D48',
          px: { xs: 2, sm: 4, md: 0, lg: 0 },
        }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 4,
          }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              pt: { xs: 1, sm: 2, md: 2, lg: 4 },
              pb: { xs: 1, sm: 2, md: 2, lg: 4 },
            }}>
            <Typography
              sx={{
                color: 'white',
                fontFamily: 'Poppins',
                fontSize: {
                  xs: '1rem',
                  sm: '1rem',
                  md: '1.5rem',
                  lg: '2.2rem',
                },
                fontWeight: '300',
                letterSpacing: '2px',
              }}>
              LONG TERM FINANCING
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'flex-start'}
            sx={{
              mt: 0,
              mb: 0,
            }}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
                fontWeight: '300',
                letterSpacing: '2px',
                color: '#f0f0f0',
              }}>
              During expansion periods, companies need to invest in civil works,
              machinery, equipment, facilities, and technology, in addition to
              requiring working capital to support these investments.
              <br />
              <br />
              These resources can be obtained through long-term financing lines
              from international and national development institutions (IFC, BID
              Invest, CAF, DEG, Proparco).
              <br />
              <br />
              Metritha fully structures these long-term financing operations
              with these institutions. The process involves:
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            sx={{ width: '100%' }}>
            <img
              src={img7}
              alt='img7'
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </Grid>
        </Grid>
        
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}>
          <ul
            style={{
              listStyleType: 'disc',
              paddingLeft: '20px',
              color: '#f0f0f0',
              fontFamily: 'Poppins',
              fontWeight: '300',
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              lineHeight: { xs: 1, sm: 2, md: 3, lg: 20 },
              letterSpacing: '2px',
            }}>
            <li style={{ marginBottom: '15px' }}>
              Analyzing the company's needs
            </li>
            <li style={{ marginBottom: '15px' }}>
              Defining the institution, financing line, amount, and appropriate
              guarantees
            </li>
            <li style={{ marginBottom: '15px' }}>
              Preparing the feasibility project tailored to each institution
            </li>
            <li style={{ marginBottom: '15px' }}>
              Overseeing the entire analysis process
            </li>
            <li style={{ marginBottom: '15px' }}>
              Negotiating with the institution on conditions, costs, terms, and
              guarantees
            </li>
            <li style={{ marginBottom: '15px' }}>
              Assisting with the contracting
            </li>
            <li style={{ marginBottom: '15px' }}>
              Monitoring the release of funds to the company
            </li>
          </ul>
        </Grid>
      </Grid> */}

      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          backgroundColor: "#331D48",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            pt: { xs: 1, sm: 2, md: 2, lg: 4 },
            pb: { xs: 1, sm: 2, md: 2, lg: 4 },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontFamily: "Poppins",
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "2.2rem",
              },
              fontWeight: "300",
              letterSpacing: "2px",
            }}
          >
            VALUATIONS & PROJECTS
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display={"flex"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: 10, sm: 12, md: 14, lg: 20 },
              fontWeight: "300",
              letterSpacing: "2px",
              color: "#f0f0f0",
              paddingX: { xs: 1, sm: 2, md: 4, lg: 36 },
            }}
          >
            To provide the aforementioned services, Metritha has developed
            technical skills that enable the preparation of various studies.
            <br />
            <br />
            The following works support the services offered and can also be
            provided independently to companies:
          </Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            gap: { xs: 1, sm: 2, md: 4, lg: 6 },
            mt: { xs: 1, sm: 2, md: 2, lg: 4 },
            paddingX: { xs: 1, sm: 2, md: 4, lg: 8 },
          }}
        >
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                color: "#f0f0f0",
                fontFamily: "Poppins",
                fontWeight: "300",
                fontSize: { xs: 12, sm: 12, md: 8, lg: 20 },
                lineHeight: { xs: 1, sm: 2, md: 3, lg: 20 },
                letterSpacing: "2px",
              }}
            >
              <li style={{ marginBottom: "15px" }}>
                Economic valuation of companies and businesses.
              </li>
              <li style={{ marginBottom: "15px" }}>Business plans.</li>
              <li style={{ marginBottom: "15px" }}>
                Buyout of partner stakes.
              </li>
              <li style={{ marginBottom: "15px" }}>
                Overseeing the entire analysis process
              </li>
              <li style={{ marginBottom: "15px" }}>
                Economic-financial feasibility studies.
              </li>
              <li style={{ marginBottom: "15px" }}>
                Assisting with the contracting
              </li>
              <li style={{ marginBottom: "15px" }}>
                Monitoring the release of funds to the company
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} sx={{ width: "100%" }}>
            <img
              src={combinedData?.images[1] ? combinedData?.images[1] : null}
              alt="img7"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                aspectRatio: "16/9",
                borderRadius: "10px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} backgroundColor={"#331D48"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          {/* Image */}
          <img
            src={bottomImg}
            alt="logo"
            style={{
              width: "80%",
              height: "80%",
              maxWidth: "100%",
            }}
          />

          {/* Overlay Content */}
          <Box
            sx={{
              position: "absolute",
              top: "30%", // Adjust to center better on small screens
              left: "50%",
              transform: "translate(-50%, -50%)", // Adjust position
              textAlign: "center",
              color: "#fff", // Text color
              zIndex: 1, // Ensure text is above the image
              padding: { xs: "10px", sm: "20px" }, // Padding for mobile
              width: { xs: "100%", sm: "80%", md: "60%" }, // Responsive width
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: "lighter",
                fontFamily: "Poppins",
                color: "#f0f0f0",
                fontSize: { xs: "12px", sm: "20px", md: "25px", lg: "40px" },
                letterSpacing: { xs: "2px", sm: "3px", md: "5px" },
              }}
            >
              Interested in our portfolio?
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: { xs: "70%", sm: "65%", md: "60%" },
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
              width: { xs: "80%", sm: "55%", md: "90%", lg: "100%" },
              // height: '50%',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <ContactBtn
              open={open}
              handleClose={handleClose}
              setWidth={{ xs: "10%", sm: "10%", md: "50%", lg: "100%" }}
            />

            <Typography
              variant="body1"
              sx={{
                fontFamily: "Poppins",
                color: "#f0f0f0",
                marginTop: "15px",
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              We will call you back in..
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#331D48",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#331D48",
            color: "#f0f0f0",
            borderRadius: "20px",
            padding: "10px 20px",
            border: "1px solid #f0f0f0",
            "&:hover": {
              backgroundColor: "#ffffff", // White background on hover
              color: "#6a1b9a", // Purple text color on hover
              border: "1px solid #6a1b9a", // Purple border on hover
            },
          }}
        >
          download brochure
        </Button>
      </Grid>
      <Footer />
    </>
  );
};

export default MetrithaServices;
